import { render, staticRenderFns } from "./addressCard.vue?vue&type=template&id=3ad7f3f8&scoped=true&"
import script from "./addressCard.vue?vue&type=script&lang=js&"
export * from "./addressCard.vue?vue&type=script&lang=js&"
import style0 from "./addressCard.vue?vue&type=style&index=0&id=3ad7f3f8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ad7f3f8",
  null
  
)

export default component.exports