import { render, staticRenderFns } from "./driverDetail.vue?vue&type=template&id=778d3acc&scoped=true&"
import script from "./driverDetail.vue?vue&type=script&lang=js&"
export * from "./driverDetail.vue?vue&type=script&lang=js&"
import style0 from "./driverDetail.vue?vue&type=style&index=0&id=778d3acc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "778d3acc",
  null
  
)

export default component.exports