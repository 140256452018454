<template>
  <div v-if="personalInfo">
    <el-row>
      <el-row v-if="isDataHidden" class="blur-text text-center mt-0 w-100 h-100">
        <el-col class="h-100">
          <div 
            class="w-100 h-100 d-flex align-items-center justify-content-center px-2"               
            @click="
              if ($store.state.User.token) {
                infoPaymentDialog = true;
              } else {
                $store.commit('toggleLoginDialog', true);
              }
            "
            style="cursor: pointer;"
          >
            <a
              class="text-white px-2"
              href="javascript:;"
              style="width: 360px;"
            >
            <div v-if="this.$store.state.User.token">
              <span v-if="personalInfo.Supplier && user.IsCarrier">
                Tedarikçi iletişim bilgisine hemen erişmek için {{ nakuAmount }}x
                <img style="width: 50px" src="../../assets/images/naku.svg" alt="" />
                ödeyiniz.
              </span>

              <span v-if="personalInfo.Supplier && !user.IsCarrier">
                Nakliyeci iletişim bilgisine hemen erişmek için {{ nakuAmount }}x
                <img style="width: 50px" src="../../assets/images/naku.svg" alt="" />
                ödeyiniz.
              </span>
            </div>
            <div v-else>
              <span v-if="personalInfo.Supplier && user.IsCarrier">
                Tedarikçi iletişim bilgisine hemen erişmek için tıklayınız.
              </span>

              <span v-if="personalInfo.Supplier && !user.IsCarrier">
                Nakliyeci iletişim bilgisine hemen erişmek için tıklayınız.
              </span>
            </div>
            
            </a>
          </div>
        </el-col>
      </el-row>
      <el-col class="position-relative" :class="{ blur: isDataHidden }">
        <el-row class="carrierContainer p-3 rounded-4">
          <el-col class="carrierBox mb-2 rounded p-3 d-flex flex-column">
            <h4>Adı Soyadı:</h4>
            <span v-if="user.IsCarrier">{{ personalInfo.Supplier ? personalInfo.ContactName : "*****" }}</span>
            <span v-else>{{ personalInfo.Supplier ? personalInfo.User.Title : "*****" }}</span>

          </el-col>
          <el-col :span="24" class="carrierBox mb-2 rounded p-3 d-flex flex-column">
            <h4>Telefon:</h4>
            <span v-if="user.IsCarrier">{{ personalInfo.Supplier ? personalInfo.ContactNumber: "*****" }}</span>
            <span v-else>{{ personalInfo.Supplier ? personalInfo.User.PhoneNumber : "*****" }}</span>

          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-dialog :close-on-click-modal="false" class="" :title="user.IsCarrier ? 'Tedarikçi Bilgilerini Görüntüle' : 'Nakliyeci Bilgilerini Görüntüle'" :visible.sync="infoPaymentDialog">
      <p class="text-white">Bu işlem karşılığında hesabınızdan {{ nakuAmount }}x NAKU kullanılacaktır. Bu işlemi onaylıyor musunuz?</p>
      <div class="d-flex justify-content-center align-items-center text-white">
        <span class="fs-20 fw-bold"> {{ nakuAmount }}x</span>
        <img src="../../assets/images/naku.svg" alt="" style="width: 120px" />
      </div>
      <span slot="footer" class="dialog-footer d-flex">
        <el-button class="bg-transparent px-5 py-3" @click="infoPaymentDialog = false">İptal</el-button>
        <el-button class="pay-button" type="primary" @click="payNakuForDetails"
          ><span class="fw-bold"> {{ nakuAmount }}x</span>
          <img src="../../assets/images/naku.svg" alt="" style="width: 20px" />
          Öde ve Görüntüle
        </el-button>
        <el-button class="pay-button" type="primary" @click="getAdvertisingVideo"
          >
          <img src="../../assets/images/naku.svg" alt="" style="width: 20px" />
          Ücretsiz Görüntüle
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" class="" title="Ücretsiz Görüntüle" :visible.sync="advertisingVideoDialog">
     <div style="text-align: center;">
      <p>Bilgileri ücretsiz görüntülemek için mobil uygulamayı kullanabilirsiniz. Mobil uygulamayı indirmek için lütfen aşağıdaki linke tıklayınız</p>
     <a href="https://onelink.to/78ju2t" target="_blank">
      <svg version="1.0" width="300" id="katman_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 600 450" style="enable-background:new 0 0 600 450;" xml:space="preserve">

<g>
	<path class="st0" d="M143,70.6h314c39.6,0,71.6,32.1,71.6,71.6l0,0c0,39.6-32.1,71.6-71.6,71.6H143c-39.6,0-71.6-32.1-71.6-71.6
		l0,0C71.4,102.7,103.5,70.6,143,70.6z"/>
	<path class="st1" d="M143,72h314c38.8,0,70.2,31.4,70.2,70.2l0,0c0,38.8-31.4,70.2-70.2,70.2H143c-38.8,0-70.2-31.4-70.2-70.2l0,0
		C72.8,103.4,104.2,72,143,72z"/>
	<path class="st2" d="M237.7,108.8v16.3h4.9c2.6,0,4.6-0.8,6.2-2.3c1.6-1.5,2.3-3.5,2.3-5.9c0-2.4-0.8-4.4-2.3-5.9
		c-1.5-1.5-3.6-2.2-6.2-2.2H237.7z M242.6,111.2c1.8,0,3.3,0.5,4.3,1.6c1.1,1,1.6,2.4,1.6,4.2c0,1.7-0.5,3.1-1.6,4.2
		c-1.1,1.1-2.5,1.6-4.3,1.6h-2.4v-11.6H242.6z"/>
	<path class="st2" d="M259.4,112.7c-1.7,0-3.2,0.6-4.4,1.8c-1.2,1.2-1.8,2.7-1.8,4.5c0,1.8,0.6,3.3,1.8,4.5c1.2,1.2,2.6,1.8,4.4,1.9
		c1.7,0,3.2-0.6,4.4-1.8c1.2-1.2,1.8-2.7,1.8-4.5c0-1.8-0.6-3.3-1.8-4.5C262.5,113.3,261.1,112.7,259.4,112.7z M259.4,115.1
		c1,0,1.9,0.4,2.6,1.2c0.7,0.7,1,1.7,1,2.8c0,1.1-0.4,2.1-1,2.8c-0.7,0.7-1.6,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1
		c-0.7-0.8-1-1.7-1-2.8c0-1.1,0.3-2.1,1-2.8C257.5,115.5,258.4,115.1,259.4,115.1z"/>
	<path class="st2" d="M280.3,120.8l-3.6-8.1h-0.9l-3.5,8.2l-3.1-7.9h-2.7l5.2,12.4h0.9l3.7-8.1l3.7,8.1h0.9L286,113h-2.7
		L280.3,120.8z"/>
	<path class="st2" d="M294,112.7c-1.4,0-2.6,0.5-3.5,1.5V113h-2.3v12.1h2.5v-7.2c0-1.7,1.1-2.8,2.7-2.8c1.5,0,2.5,1.1,2.5,2.8v7.2
		h2.5v-7.6C298.4,114.6,296.7,112.7,294,112.7z"/>
	<path class="st2" d="M301.8,106.6v18.5h2.5v-18.5H301.8z"/>
	<path class="st2" d="M313.3,112.7c-1.7,0-3.2,0.6-4.4,1.8c-1.2,1.2-1.8,2.7-1.8,4.5c0,1.8,0.6,3.3,1.8,4.5c1.2,1.2,2.6,1.8,4.4,1.9
		c1.7,0,3.2-0.6,4.4-1.8c1.2-1.2,1.8-2.7,1.8-4.5c0-1.8-0.6-3.3-1.8-4.5C316.5,113.3,315,112.7,313.3,112.7z M313.4,115.1
		c1,0,1.9,0.4,2.6,1.2c0.7,0.7,1,1.7,1,2.8c0,1.1-0.4,2.1-1,2.8c-0.7,0.7-1.6,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1
		c-0.7-0.8-1-1.7-1-2.8c0-1.1,0.3-2.1,1-2.8C311.4,115.5,312.3,115.1,313.4,115.1z"/>
	<path class="st2" d="M326.9,112.7c-2.1,0-3.7,0.9-4.9,2.6l1.4,1.5c1-1.2,2.1-1.8,3.2-1.8c1.6,0,2.5,0.9,2.5,2.6v0.8h-3.3
		c-2.6,0-4.3,1.4-4.3,3.5c0,2.1,1.6,3.5,4.1,3.5c1.6,0,2.8-0.5,3.7-1.6v1.4h2.3v-7.3C331.6,114.5,329.7,112.7,326.9,112.7z
		 M326.1,123.4c-1.2,0-2-0.6-2-1.6c0-1,0.7-1.5,2.1-1.5h3v0.2C329.1,122.2,327.8,123.4,326.1,123.4z"/>
	<path class="st2" d="M343.9,114.5c-0.9-1.2-2.2-1.8-3.9-1.8c-1.6,0-3,0.6-4.1,1.8c-1.1,1.2-1.6,2.7-1.6,4.5c0,1.8,0.5,3.3,1.6,4.5
		c1.1,1.2,2.4,1.8,4.1,1.8c1.8,0,3.2-0.7,4.1-2v1.7h2.3v-18.5h-2.5V114.5z M340.5,123c-1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1-1.7-1-2.9
		c0-1.2,0.3-2.1,1-2.8c0.7-0.7,1.5-1.1,2.6-1.1c0.9,0,1.8,0.3,2.5,1c0.7,0.7,1,1.7,1,2.9C344,121.7,342.4,123,340.5,123z"/>
	<path class="st2" d="M362.1,112.7c-1.7,0-3.2,0.6-4.4,1.8c-1.2,1.2-1.8,2.7-1.8,4.5c0,1.8,0.6,3.3,1.8,4.5c1.2,1.2,2.6,1.8,4.4,1.9
		c1.7,0,3.2-0.6,4.4-1.8c1.2-1.2,1.8-2.7,1.8-4.5c0-1.8-0.6-3.3-1.8-4.5C365.2,113.3,363.8,112.7,362.1,112.7z M362.1,115.1
		c1,0,1.9,0.4,2.6,1.2c0.7,0.7,1,1.7,1,2.8c0,1.1-0.4,2.1-1,2.8c-0.7,0.7-1.6,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1
		c-0.7-0.8-1-1.7-1-2.8c0-1.1,0.3-2.1,1-2.8C360.2,115.5,361.1,115.1,362.1,115.1z"/>
	<path class="st2" d="M376.9,112.7c-1.4,0-2.6,0.5-3.5,1.5V113h-2.3v12.1h2.5v-7.2c0-1.7,1.1-2.8,2.7-2.8c1.5,0,2.5,1.1,2.5,2.8v7.2
		h2.5v-7.6C381.3,114.6,379.7,112.7,376.9,112.7z"/>
	<path class="st2" d="M396.8,123c-1.5,0-2.3-0.5-2.3-2.1v-5.7h3.5V113h-3.5v-3.2H392v3.2h-1.9v2.2h1.9v5.9c0,2.8,1.6,4.2,4.3,4.2
		c0.6,0,1.3-0.1,2.1-0.3v-2.2C397.6,123,397.1,123,396.8,123z"/>
	<path class="st2" d="M406.7,112.7c-1.4,0-2.5,0.5-3.4,1.5v-7.6h-2.5v18.5h2.5v-7.2c0-1.7,1.1-2.8,2.7-2.8c1.5,0,2.5,1.1,2.5,2.8
		v7.2h2.5v-7.6C411,114.7,409.4,112.7,406.7,112.7z"/>
	<path class="st2" d="M425.2,118.3c0-1.6-0.5-2.9-1.5-4c-1-1.1-2.3-1.6-3.8-1.6c-1.7,0-3.1,0.6-4.3,1.9c-1.2,1.2-1.7,2.7-1.7,4.5
		c0,1.8,0.6,3.3,1.8,4.5c1.2,1.2,2.7,1.8,4.5,1.8c1.8,0,3.3-0.6,4.6-2l-1.2-1.8c-1,1-2.2,1.4-3.4,1.4c-2,0-3.5-1.2-3.8-3.2h8.7
		C425.1,119.3,425.2,118.8,425.2,118.3z M419.7,114.9c1.7,0,3,1.2,3,3h-6.3C416.7,116.1,418,114.9,419.7,114.9z"/>
	<path class="st2" d="M264,168.6L251,137.5h-1.6l-12.9,31.1h5l2.1-5.1h13.2l2.1,5.1H264z M245.3,159.3l4.9-12.7l4.8,12.7H245.3z"/>
	<path class="st2" d="M279.4,145.3c-3.2,0-6,1.4-7.6,3.8v-3.2h-4.4v31.1h4.7v-11.1c1.7,2.2,4.2,3.2,7.3,3.2c3.1,0,5.7-1.1,7.7-3.4
		c2-2.3,3-5.1,3-8.5c0-3.4-1-6.2-3-8.5C285.1,146.4,282.5,145.3,279.4,145.3z M278.6,164.6c-1.8,0-3.4-0.6-4.7-1.9
		c-1.3-1.3-1.9-3.1-1.9-5.6c0-4.9,3-7.4,6.6-7.4c1.9,0,3.5,0.7,4.8,2.1c1.3,1.4,1.9,3.2,1.9,5.4c0,2.2-0.6,3.9-1.9,5.3
		C282.1,163.9,280.5,164.6,278.6,164.6z"/>
	<path class="st2" d="M306.5,145.3c-3.2,0-6,1.4-7.6,3.8v-3.2h-4.4v31.1h4.7v-11.1c1.7,2.2,4.2,3.2,7.3,3.2c3.1,0,5.7-1.1,7.7-3.4
		c2-2.3,3-5.1,3-8.5c0-3.4-1-6.2-3-8.5C312.2,146.4,309.6,145.3,306.5,145.3z M305.8,164.6c-1.8,0-3.4-0.6-4.7-1.9
		c-1.3-1.3-1.9-3.1-1.9-5.6c0-4.9,3-7.4,6.6-7.4c1.9,0,3.5,0.7,4.8,2.1c1.3,1.4,1.9,3.2,1.9,5.4c0,2.2-0.6,3.9-1.9,5.3
		C309.2,163.9,307.7,164.6,305.8,164.6z"/>
	<path class="st2" d="M342.5,137.5c-2.6,0-4.8,0.8-6.6,2.3c-1.8,1.5-2.7,3.5-2.7,6c0,3.9,2.5,6.4,6.8,8.5l3.2,1.6
		c3.1,1.5,4.3,2.4,4.3,4.6c0,2.6-2,4.3-5.1,4.3c-3,0-5.5-1.6-7.7-4.9l-3.3,2.6c2.3,4.1,6.3,6.6,11.1,6.6c2.8,0,5.2-0.8,7-2.4
		c1.9-1.6,2.8-3.8,2.8-6.4c0-4-2.3-6.4-7-8.7l-3.3-1.6c-3-1.5-4.1-2.3-4.1-4.4c0-2.4,2-3.7,4.4-3.7c2.5,0,4.6,1.3,6.2,3.7l3.3-2.6
		C350.1,139.7,346.6,137.5,342.5,137.5z"/>
	<path class="st2" d="M367.1,164.7c-2.8,0-4.3-1-4.3-3.9V150h6.5v-4.2h-6.5v-6h-4.7v6h-3.6v4.2h3.6v11.1c0,5.3,3,7.9,8,7.9
		c1.1,0,2.4-0.2,3.9-0.5v-4.1C368.7,164.6,367.8,164.7,367.1,164.7z"/>
	<path class="st2" d="M383.5,145.3c-3.2,0-6,1.2-8.2,3.4c-2.2,2.3-3.3,5.1-3.3,8.4c0,3.3,1.1,6.2,3.3,8.4c2.2,2.3,4.9,3.4,8.2,3.5
		c3.2,0,5.9-1.2,8.2-3.4c2.3-2.3,3.4-5.1,3.4-8.5c0-3.3-1.2-6.2-3.4-8.4C389.5,146.5,386.7,145.3,383.5,145.3z M383.6,149.8
		c1.9,0,3.5,0.7,4.8,2.2c1.3,1.4,2,3.1,2,5.3c0,2.1-0.7,3.8-2,5.3c-1.3,1.4-2.9,2.1-4.8,2.1c-2,0-3.6-0.7-4.8-2.1
		c-1.3-1.4-1.9-3.2-1.9-5.3c0-2.1,0.6-3.9,1.9-5.3C380,150.5,381.6,149.8,383.6,149.8z"/>
	<path class="st2" d="M410.3,145.6c-2.7,0-4.8,1.1-6.4,3.2v-3h-4.4v22.7h4.7v-12.1c0-4,2.7-6.3,6.4-6.3h1.4v-4.3
		C411.6,145.7,411,145.6,410.3,145.6z"/>
	<path class="st2" d="M435,155.7c0-3-1-5.5-2.9-7.5c-1.9-2-4.3-3-7.2-3c-3.2,0-5.9,1.2-8,3.5c-2.2,2.3-3.2,5.1-3.2,8.4
		c0,3.4,1.1,6.2,3.3,8.5c2.2,2.3,5,3.4,8.4,3.4c3.3,0,6.2-1.2,8.7-3.7l-2.2-3.3c-2,1.8-4.1,2.7-6.4,2.7c-3.8,0-6.5-2.2-7.1-6h16.3
		C434.8,157.7,435,156.7,435,155.7z M424.7,149.4c3.2,0,5.6,2.2,5.6,5.6h-11.9C419.1,151.6,421.6,149.4,424.7,149.4z"/>
	<path class="st2" d="M214.8,140.2c-0.1-8.7,7.6-12.9,7.9-13.1c-4.3-5.9-11.1-6.7-13.4-6.8c-5.6-0.6-11.1,3.2-14,3.2
		c-2.9,0-7.4-3.1-12.1-3c-6.1,0.1-11.9,3.4-15,8.6c-6.5,10.5-1.7,26,4.6,34.5c3.1,4.2,6.7,8.8,11.5,8.7c4.7-0.2,6.4-2.8,12-2.8
		c5.6,0,7.2,2.8,12.1,2.7c5-0.1,8.2-4.2,11.2-8.4c3.6-4.8,5-9.5,5.1-9.7C224.5,154,214.9,150.6,214.8,140.2z M205.6,114.8
		c2.5-2.9,4.2-6.9,3.7-11c-3.6,0.1-8.2,2.3-10.8,5.2c-2.3,2.5-4.4,6.7-3.8,10.6C198.8,119.9,203,117.6,205.6,114.8z"/>
	<path class="st0" d="M143,236.2h314c39.6,0,71.6,32.1,71.6,71.6l0,0c0,39.6-32.1,71.6-71.6,71.6H143c-39.6,0-71.6-32.1-71.6-71.6
		l0,0C71.4,268.2,103.5,236.2,143,236.2z"/>
	<path class="st1" d="M143,237.5h314c38.8,0,70.2,31.4,70.2,70.2l0,0c0,38.8-31.4,70.2-70.2,70.2H143c-38.8,0-70.2-31.4-70.2-70.2
		l0,0C72.8,269,104.2,237.5,143,237.5z"/>
	<path class="st2" d="M188.7,306.3l-34.1-31.9c-0.3,0.8-0.6,1.6-0.6,2.7v58.7c0,1.1,0.3,1.9,0.6,2.7L188.7,306.3z"/>
	<path class="st2" d="M192.7,306.3l11.4,10.6l12.5-6.6c4-2.1,4-5.6,0-7.7l-12.8-6.9L192.7,306.3z"/>
	<path class="st2" d="M190.7,304.4l10.5-10.1l-40.1-21.2c-1.7-0.8-3.4-1.1-4.5-0.5L190.7,304.4z"/>
	<path class="st2" d="M190.7,308.1l-34.1,32.1c1.1,0.5,2.8,0.3,4.8-0.5l40.3-21.2L190.7,308.1z"/>
	<path class="st2" d="M244.9,276.6c-2.3,0-4.3,0.8-5.9,2.4c-1.6,1.6-2.4,3.6-2.4,6c0,2.4,0.8,4.4,2.4,6c1.6,1.6,3.6,2.4,5.9,2.4
		c3.2,0,5.9-1.7,7.3-4.3v-4.6h-7.4v2.3h4.8v1.8c-1.1,1.7-2.6,2.5-4.7,2.5c-1.7,0-3.1-0.6-4.2-1.7c-1.1-1.2-1.6-2.6-1.6-4.4
		c0-1.7,0.5-3.2,1.6-4.3c1-1.1,2.4-1.7,4.1-1.7c2,0,3.5,0.9,4.7,2.6l1.8-1.6C249.9,277.8,247.7,276.6,244.9,276.6z"/>
	<path class="st2" d="M255.4,276.9v16.3H266v-2.3h-8.1v-5.2h5.9v-2.3h-5.9v-4.2h7.8v-2.3H255.4z"/>
	<path class="st2" d="M267.3,276.9v2.3h5.3v14h2.5v-14h5.3v-2.3H267.3z"/>
	<path class="st2" d="M288.3,276.9v16.3h2.5v-16.3H288.3z"/>
	<path class="st2" d="M293.2,276.9v2.3h5.3v14h2.5v-14h5.3v-2.3H293.2z"/>
	<path class="st2" d="M321.4,276.6c-2.3,0-4.3,0.8-5.9,2.4c-1.6,1.6-2.4,3.6-2.4,6c0,2.4,0.8,4.4,2.4,6c1.6,1.6,3.5,2.4,5.9,2.4
		c2.3,0,4.2-0.8,5.8-2.4c1.6-1.6,2.4-3.6,2.4-6c0-2.4-0.8-4.4-2.4-6C325.7,277.4,323.7,276.6,321.4,276.6z M321.4,279
		c1.6,0,3,0.6,4,1.7c1.1,1.1,1.6,2.6,1.6,4.3c0,1.8-0.5,3.2-1.6,4.3c-1.1,1.1-2.4,1.7-4,1.7c-1.6,0-3-0.6-4-1.7
		c-1.1-1.2-1.6-2.6-1.6-4.3c0-1.7,0.5-3.2,1.6-4.3C318.5,279.6,319.8,279,321.4,279z"/>
	<path class="st2" d="M343.1,287.9l-9.5-11.3h-0.9v16.6h2.5v-11l9.6,11.3h0.9v-16.6h-2.5V287.9z"/>
	<path class="st2" d="M251.1,305.3c-4.1,0-7.5,1.4-10.3,4.3c-2.8,2.8-4.2,6.3-4.2,10.5c0,4.2,1.4,7.7,4.2,10.5
		c2.8,2.8,6.2,4.2,10.3,4.2c5.5,0,10.4-3,12.8-7.5v-8h-12.9v4.1h8.5v3.1c-1.9,2.9-4.6,4.3-8.2,4.3c-3,0-5.4-1-7.3-3
		s-2.8-4.6-2.8-7.7c0-3,0.9-5.5,2.7-7.5c1.8-2,4.2-3,7.1-3c3.4,0,6.2,1.5,8.2,4.5l3.2-2.7C259.8,307.3,256,305.3,251.1,305.3z"/>
	<path class="st2" d="M276.5,312.6c-3,0-5.6,1.1-7.7,3.2c-2.1,2.1-3.1,4.8-3.1,7.9c0,3.1,1,5.8,3.1,7.9c2.1,2.1,4.6,3.2,7.7,3.2
		c3,0,5.5-1.1,7.7-3.2c2.1-2.2,3.2-4.8,3.2-7.9c0-3.1-1.1-5.8-3.2-7.9C282.1,313.7,279.5,312.6,276.5,312.6z M276.6,316.8
		c1.8,0,3.3,0.7,4.5,2c1.2,1.3,1.8,2.9,1.8,4.9c0,1.9-0.6,3.6-1.8,4.9c-1.2,1.3-2.7,1.9-4.5,1.9c-1.8,0-3.3-0.6-4.5-1.9
		c-1.2-1.3-1.8-3-1.8-4.9c0-2,0.6-3.6,1.8-4.9C273.2,317.4,274.7,316.8,276.6,316.8z"/>
	<path class="st2" d="M299.8,312.6c-3,0-5.6,1.1-7.7,3.2c-2.1,2.1-3.1,4.8-3.1,7.9c0,3.1,1,5.8,3.1,7.9c2.1,2.1,4.6,3.2,7.7,3.2
		c3,0,5.5-1.1,7.7-3.2c2.1-2.2,3.2-4.8,3.2-7.9c0-3.1-1.1-5.8-3.2-7.9C305.4,313.7,302.8,312.6,299.8,312.6z M299.9,316.8
		c1.8,0,3.3,0.7,4.5,2c1.2,1.3,1.8,2.9,1.8,4.9c0,1.9-0.6,3.6-1.8,4.9c-1.2,1.3-2.7,1.9-4.5,1.9c-1.8,0-3.3-0.6-4.5-1.9
		c-1.2-1.3-1.8-3-1.8-4.9c0-2,0.6-3.6,1.8-4.9C296.5,317.4,298,316.8,299.9,316.8z"/>
	<path class="st2" d="M329.7,316.1c-1.6-2.3-4-3.5-7.2-3.5c-2.9,0-5.2,1-7.1,3c-1.9,2-2.8,4.5-2.8,7.7c0,3.1,1,5.7,2.8,7.7
		c1.9,2,4.2,3,7.1,3c3,0,5.2-1,6.9-3v1.5c0,4-2.4,6.1-6.1,6.1c-2.4,0-4.8-1-7.2-3l-2.4,3.2c2.7,2.7,6,4,9.8,4
		c6.2,0,10.2-3.8,10.2-10.5v-19h-4V316.1z M323.2,329.9c-1.8,0-3.2-0.6-4.5-1.8c-1.2-1.3-1.8-2.9-1.8-4.8c0-1.9,0.6-3.5,1.8-4.8
		c1.2-1.2,2.7-1.8,4.5-1.8c3.4,0,6.2,2.2,6.2,6.5c0,2.2-0.6,3.8-1.8,5C326.3,329.3,324.9,329.9,323.2,329.9z"/>
	<path class="st2" d="M337.9,301.9v32.4h4.4v-32.4H337.9z"/>
	<path class="st2" d="M365.3,322.3c0-2.8-0.9-5.1-2.7-7c-1.8-1.9-4-2.8-6.7-2.8c-3,0-5.5,1.1-7.5,3.2c-2,2.1-3,4.8-3,7.9
		c0,3.2,1,5.8,3.1,7.9c2.1,2.1,4.7,3.2,7.9,3.2c3.1,0,5.8-1.1,8.2-3.4l-2.1-3.1c-1.8,1.7-3.8,2.5-6,2.5c-3.5,0-6.1-2.1-6.7-5.6H365
		C365.2,324.2,365.3,323.2,365.3,322.3z M355.7,316.5c3,0,5.2,2,5.2,5.2h-11.1C350.5,318.5,352.8,316.5,355.7,316.5z"/>
	<path class="st2" d="M378.1,305.8v28.5h4.4v-11h5.6c2.8,0,5-0.8,6.8-2.4c1.7-1.6,2.6-3.8,2.6-6.4s-0.9-4.7-2.6-6.3
		c-1.7-1.6-4-2.4-6.8-2.4H378.1z M388.1,309.8c3.1,0,4.9,1.9,4.9,4.7c0,2.8-1.8,4.7-4.9,4.7h-5.5v-9.4H388.1z"/>
	<path class="st2" d="M400,301.9v32.4h4.4v-32.4H400z"/>
	<path class="st2" d="M416.5,312.6c-3.7,0-6.6,1.5-8.6,4.5l2.5,2.7c1.7-2.1,3.6-3.2,5.7-3.2c2.8,0,4.4,1.6,4.4,4.5v1.4h-5.8
		c-4.6,0-7.5,2.4-7.5,6.1c0,3.7,2.8,6.1,7.1,6.1c2.8,0,5-1,6.5-2.9v2.4h4.1v-12.8C424.9,315.8,421.6,312.6,416.5,312.6z
		 M415.2,331.3c-2.1,0-3.6-1-3.6-2.8c0-1.7,1.2-2.6,3.6-2.6h5.2v0.4C420.5,329.2,418.1,331.3,415.2,331.3z"/>
	<path class="st2" d="M437.1,328.6l-5.9-15.5h-4.7l8.2,20.3l-0.8,2c-1,2.4-2.4,3.1-4.5,3.1c-0.3,0-1.1,0-2.4-0.2v3.8
		c1.1,0.3,2.3,0.4,3.4,0.4c3.5,0,5.8-1.8,7.5-5.8l10-23.6h-4.7L437.1,328.6z"/>
</g>
</svg>
</a>
</div>
      <!-- Video Player -->
    <!-- <video ref="videoPlayer" width="100%" controls @ended="closeAdvertisingDialog" autoplay playsinline style="pointer-events: none;">
      <source :src="advertisingVideoUrl" type="video/mp4">
   
    </video> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["personalInfo", "nakuAmount", "adId"],
  data() {
    return {
      infoPaymentDialog: false,
      advertisingVideoDialog : false,
      advertisingVideoUrl:"",
      advertisingVideoID:""

    };
  },
  methods: {
    async payNakuForDetails() {
    
      const result = await this.$store.dispatch("payNakuForDetails", {
        id: { Id: this.adId },
        advertId: { AdvertId: this.adId },
      });
      if (!result.HasError) {
        this.infoPaymentDialog = false;
      }
    },
    async closeAdvertisingDialog(){
      const result = await this.$store.dispatch("payNakuForDetails", {
        id: { Id: this.adId },
        advertId: { AdvertId: this.adId },
        advertisingVideoId: this.advertisingVideoID
      });
      this.advertisingVideoDialog = false;
      
    },
    async getAdvertisingVideo() {
 
    const result = await this.$store.dispatch("getAdvertisingVideo", {
      id: { Id: this.adId },
      advertId: { AdvertId: this.adId },
    });
    var videoSource = result.Data.VideoSource;
    this.advertisingVideoUrl = videoSource;
    this.advertisingVideoDialog = true;
    this.advertisingVideoID = result.Data.ID;
    console.log(videoSource);
    if (!result.HasError) {
      this.infoPaymentDialog = false;
    }
  },
  },
  computed: {
    user() {
      return this.$store.getters["userState"];
    },
    paidAdverts() {
      if (this.user) {
        return this.user.PaidAdverts || [];
      }
      return [];
    },
    isDataHidden() {
      return !this.paidAdverts.some(item => item.AdvertId === this.adId);
    }
  }
};
</script>

<style scoped>
/* Video oynatıcının kontrol panelini gizle */
video::-webkit-media-controls {
  display: none !important;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}
video::-webkit-media-controls-panel {
  display: none !important;
}
video {
  width: 100%;
  height: auto;
}
.pay-button {
  white-space: normal !important;
  word-wrap: break-word;
}
.blured-row {
  filter: blur(4px);
}
.shipmentDetail {
  position: relative;
  border-radius: 16px;
  background-color: #4d668a;
  width: 100%;
  margin-top: 20px;
  padding: 20px 30px;
  margin-bottom: 20px;
}
.st0{fill:#FFFFFF;}
	.st1{fill:none;stroke:#010202;stroke-width:2.7543;stroke-opacity:0.25;}
	.st2{fill:#010202;}
.detailDivider {
  border-bottom: 1px dashed white;
  margin: 15px 0;
}
.detailSection {
  background-color: #2d405a;
  border-radius: 16px;
  padding: 10px 15px;
}
.blur-text {
  position: absolute;
  z-index: 1;
}
.carrierContainer {
  background-color: #4d668a;
}
.carrierBox {
  background-color: #2d405a;
  color: white;
}
</style>
